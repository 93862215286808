/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Subtitle, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"EIG"} description={"Electric Installations Group"}>
        <Column className="css-1gut6eh --style4 --center bg--center --full" name={"83mrtmkdfme"} lightbox={false} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(17,20,28,0.65), rgba(17,20,28,0.65)), url(https://cdn.swbpg.com/t/936/41e88e428c1b42ed93b38cc8bd7f4870_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(17,20,28,0.65), rgba(17,20,28,0.65)), url(https://cdn.swbpg.com/t/936/41e88e428c1b42ed93b38cc8bd7f4870_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(17,20,28,0.65), rgba(17,20,28,0.65)), url(https://cdn.swbpg.com/t/936/41e88e428c1b42ed93b38cc8bd7f4870_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(17,20,28,0.65), rgba(17,20,28,0.65)), url(https://cdn.swbpg.com/t/936/41e88e428c1b42ed93b38cc8bd7f4870_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(17,20,28,0.65), rgba(17,20,28,0.65)), url(https://cdn.swbpg.com/t/936/41e88e428c1b42ed93b38cc8bd7f4870_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(17,20,28,0.65), rgba(17,20,28,0.65)), url(https://cdn.swbpg.com/t/936/41e88e428c1b42ed93b38cc8bd7f4870_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(17,20,28,0.65), rgba(17,20,28,0.65)), url(https://cdn.swbpg.com/t/936/41e88e428c1b42ed93b38cc8bd7f4870_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(17,20,28,0.65), rgba(17,20,28,0.65)), url(https://cdn.swbpg.com/t/936/41e88e428c1b42ed93b38cc8bd7f4870_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --hvr2 --shadow4 --center el--1 flex--center" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="--hvr5 --shape5 --shadow5" style={{"maxWidth":494,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image className="--shape3" style={{"maxWidth":466}} src={"https://cdn.swbpg.com/t/936/659050b4459b4387a995d0c793b72e35_.png"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} RootClassName={"column__pic"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--style5 subtitle-box--shadow2 subtitle-box--center ff--1 fs--18 w--600 subtitle-box--invert ls--008 lh--14" style={{"maxWidth":1000}} content={"<span style=\"color: rgb(24, 24, 24); background-color: rgb(255, 255, 255); font-weight: bold;\"><br>Electric Installations Group provide first class electrical installations and maintenance services.<br>Established in 1997, with qualified staff able to offer a complete service to meet your technical requirements.<br></span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--30" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"zg0ma468du0w"} animS={"3"} border={null} layout={"l10"}>
          
          <ColumnWrap className="column__flex --shape5 --border5 --shadow5 el--4 mb--80 mt--80 pl--0 pr--02 pt--0 flex--center" style={{"backgroundColor":"rgba(32,32,32,1)"}} border={"5"} columns={"4"} fullscreen={false}>
            
            <ColumnWrapper className="--center mb--80">
              
              <Image className="--shape4 --shadow5" src={"https://cdn.swbpg.com/t/936/d348d2af1d8447acac43e9d02dafb64a_s=660x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/936/d348d2af1d8447acac43e9d02dafb64a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/936/d348d2af1d8447acac43e9d02dafb64a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/936/d348d2af1d8447acac43e9d02dafb64a_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

              <Subtitle className="subtitle-box w--600" content={"<span style=\"color: rgb(255, 255, 255);\">Electrical Installation</span><br>"}>
              </Subtitle>

              <Text className="text-box text-box--justify text-box--invert" content={"We provide wiring in industrial and production halls, assembly of complete technological units such as 3D lasers, conveyor systems, street LED lighting, electric floor heating and many more.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--80">
              
              <Image className="--shape4 --shadow5" style={{"marginBottom":0}} src={"https://cdn.swbpg.com/t/936/0b2c79e6095d452fbe45417c9e69cf5d_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/936/0b2c79e6095d452fbe45417c9e69cf5d_s=350x_.png 350w"} RootClassName={"column__pic"}>
              </Image>

              <Subtitle className="subtitle-box w--600" content={"<span style=\"color: rgb(255, 255, 255);\">Digital Services</span><br>"}>
              </Subtitle>

              <Text className="text-box text-box--justify text-box--invert" content={"We have been building IPTV, KTV and satellite networks since 2001. As well as installation of security systems and camera systems.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--80">
              
              <Image className="--shape4 --shadow5" style={{"maxWidth":224,"marginBottom":0,"backgroundColor":"rgba(22,42,233,1)"}} src={"https://cdn.swbpg.com/o/936/4ada6bd60a564b1483fb8069297af3a4.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} RootClassName={"column__pic"}>
              </Image>

              <Subtitle className="subtitle-box w--600" content={"<span style=\"color: rgb(255, 255, 255);\">Revision of electrical installations</span><br>"}>
              </Subtitle>

              <Text className="text-box text-box--justify text-box--invert" content={"Our company also offer Revision of electric devices, installations, revision of electrical hand-operated tools, portable and non-portable appliances.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--80" style={{"paddingBottom":57.506256103515625}}>
              
              <Image className="--shape4 --shadow5" src={"https://cdn.swbpg.com/t/936/1535116235_s=350x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/936/1535116235_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

              <Subtitle className="subtitle-box w--600" content={"<span style=\"color: rgb(255, 255, 255);\">LED screens, scoreboards</span><br>"}>
              </Subtitle>

              <Text className="text-box text-box--justify text-box--invert mb--0 pt--0" content={"VISIO electronic is part of our group dealing with the installation and service of LED screens, scoreboards.<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"rjkr6dat9d"} lightbox={false}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--shape3 --shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/936/d5ed452aa9634ac5a8d8e8c2d5b224b5_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/936/d5ed452aa9634ac5a8d8e8c2d5b224b5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/936/d5ed452aa9634ac5a8d8e8c2d5b224b5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/936/d5ed452aa9634ac5a8d8e8c2d5b224b5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/936/d5ed452aa9634ac5a8d8e8c2d5b224b5_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/936/d5ed452aa9634ac5a8d8e8c2d5b224b5_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape3 --shadow5 pb--0" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/936/693c803aeb8648f8a416e5b17feceafa_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/936/693c803aeb8648f8a416e5b17feceafa_s=350x_.JPG 350w, https://cdn.swbpg.com/t/936/693c803aeb8648f8a416e5b17feceafa_s=660x_.JPG 660w, https://cdn.swbpg.com/t/936/693c803aeb8648f8a416e5b17feceafa_s=860x_.JPG 860w, https://cdn.swbpg.com/t/936/693c803aeb8648f8a416e5b17feceafa_s=1400x_.JPG 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape3 --shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/936/8dbd39c4564a46339b31dcf6279fab22_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/936/8dbd39c4564a46339b31dcf6279fab22_s=350x_.jpg 350w, https://cdn.swbpg.com/t/936/8dbd39c4564a46339b31dcf6279fab22_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape3 --shadow5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/936/4feb424c00564998b1ecd6fd700de427_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/936/4feb424c00564998b1ecd6fd700de427_s=350x_.jpg 350w, https://cdn.swbpg.com/t/936/4feb424c00564998b1ecd6fd700de427_s=660x_.jpg 660w, https://cdn.swbpg.com/t/936/4feb424c00564998b1ecd6fd700de427_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"czvr94zdan25"}>
          
          <ColumnWrap className="column__flex --shape2 el--3 mb--0" style={{"maxWidth":993}} columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image className="--shape4 --shadow5" style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/936/659050b4459b4387a995d0c793b72e35_.png"} svg={false} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"marginTop":2.524993896484375,"paddingBottom":0}} border={null}>
              
              <Subtitle className="subtitle-box ff--1 fs--18 subtitle-box--invert" content={"Contact us :"}>
              </Subtitle>

              <Text className="text-box text-box--invert mt--10" content={"Tel.: +420 702 676 636, 702 676 637"}>
              </Text>

              <Text className="text-box ff--1 fs--11 text-box--invert lh--16 mt--02" content={"Email: info@eigcz.com<br>www.eigcz.com<br><br>Electric Installations Group LTD.\n\nEnterprise House 2 Pass Street, Oldham, Manchester, United Kingdom, OL9 6HZ. Correspondence adress: Minshull House, 67 Wellington Road North, Stockport, Chesire, United Kingdom, SK4 2LP.\n\nElectric Installations Group LTD. - odštěpný závod Praha, Česká Republika, Braunerova 563/7, Praha 8, 180 00<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}